import { useEffect, useCallback } from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";

import BuildingSearchBox from "../BuildingSearchBox";
import BlockSearchBox from "../BlockSearchBox";
import UnitSearchBox from "../UnitSearchBox";

import { PROPERTY_NEIGHBORHOOD_OPTION } from "./enum";
import { useAddressSearch, WrappedProps } from "./context";

interface AddressSearchProps {
  isCompactMode?: boolean;
}

const AddressSearch: React.FC<AddressSearchProps> = ({
  isCompactMode = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const {
    selectedBuilding,
    setSelectedBuilding,
    selectedBuildingBlock,
    setSelectedBuildingBlock,
    selectedBuildingBlockUnit,
    setSelectedBuildingBlockUnit,
    searchBuildings,
    loadingBuildings,
    searchBuildingBlocks,
    loadingBuildingBlocks,
    searchBuildingBlockUnits,
    loadingBuildingBlockUnits,
    buildings,
    buildingBlocks,
    setBuildingBlocks,
    buildingBlockUnits,
    setBuildingBlockUnits,
  }: WrappedProps = useAddressSearch();

  const resetSearchFields = useCallback(() => {
    setSelectedBuildingBlock(null);
    setSelectedBuildingBlockUnit(null);
    setBuildingBlocks([]);
    setBuildingBlockUnits([]);
  }, [
    setSelectedBuildingBlock,
    setSelectedBuildingBlockUnit,
    setBuildingBlocks,
    setBuildingBlockUnits,
  ]);

  useEffect(() => {
    if (selectedBuilding) {
      resetSearchFields();

      setBuildingBlocks(
        selectedBuilding.block_ids
          .reduce((acc, val, idx) => {
            return acc.concat({
              _id: val,
              name: selectedBuilding.block_names[idx]
                ? selectedBuilding.block_names[idx]
                : PROPERTY_NEIGHBORHOOD_OPTION.NEIGHBORHOOD_NAME_UNEXISTENT,
            });
          }, [])
          .sort((a, b) => a.name.localeCompare(b.name))
      );
    }
  }, [selectedBuilding, setBuildingBlocks, resetSearchFields]);

  const preparingBuildingBlocks =
    !buildingBlocks.length || !selectedBuilding || loadingBuildings;
  const preparingBuildingBlockUnits =
    !buildingBlockUnits.length ||
    !selectedBuilding ||
    !selectedBuildingBlock ||
    loadingBuildingBlocks;

  return (
    <Box width={1}>
      {!isCompactMode && (
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            px: 2,
            py: 1,
          }}
          width={1}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            주소를 검색하여 자이랜드 추정시세 및 시장분석을 이용해보세요
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "center",
        }}
        width={1}
      >
        <BuildingSearchBox
          searchBuildings={searchBuildings}
          loadingBuildings={loadingBuildings}
          buildings={buildings}
          setSelectedBuilding={setSelectedBuilding}
          resetSelection={() => {
            setSelectedBuilding(null);
            setSelectedBuildingBlock(null);
            setSelectedBuildingBlockUnit(null);
          }}
          selectedBuilding={selectedBuilding}
        />
        <BlockSearchBox
          searchBuildingBlocks={searchBuildingBlocks}
          loadingBuildingBlocks={loadingBuildingBlocks}
          buildingBlocks={buildingBlocks}
          selectedBuildingBlock={selectedBuildingBlock}
          disabled={preparingBuildingBlocks}
        />
        <UnitSearchBox
          searchBuildingBlockUnits={searchBuildingBlockUnits}
          loadingBuildingBlockUnits={loadingBuildingBlockUnits}
          buildingBlockUnits={buildingBlockUnits}
          selectedBuildingBlock={selectedBuildingBlock}
          selectedBuildingBlockUnit={selectedBuildingBlockUnit}
          disabled={preparingBuildingBlocks || preparingBuildingBlockUnits}
        />
      </Box>
      {!isCompactMode && (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            px: 2,
            py: 0.5,
          }}
          width={1}
        >
          <Typography
            variant="subtitle2"
            sx={{ color: theme.palette.grey[400], fontSize: 12 }}
          >
            e.g., 경기도 남양주시 화도읍 창현리 777번지 이나 경기 남양주시
            화도읍 수레로 1176{" "}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default AddressSearch;
